import { render, staticRenderFns } from "./EntityProduct.vue?vue&type=template&id=119ce4d8&"
import script from "./EntityProduct.vue?vue&type=script&lang=ts&"
export * from "./EntityProduct.vue?vue&type=script&lang=ts&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports