import request from '@/utils/request';
import { IRequestResult } from '../type/tool';
import { IBaseOrder, IBaseShoppingCartItem } from '../type/base';

export function postShoppingCartList(
  data: Partial<
    Pick<IBaseOrder, 'orderCode' | 'orderState' | 'payState' | 'deliverState' | 'payType'> &
      Pick<IBaseShoppingCartItem, 'skuName' | 'spuName'> & {
        /* 支付时间开始 */
        payTimeBegin: string;
        /* 支付时间结束 */
        payTimeEnd: string;
        /* 发货时间开始 */
        deliverTimeBegin: string;
        /* 发货时间结束 */
        deliverTimeEnd: string;
        /* 下单时间结束 */
        orderTimeEnd: string;
        /* 下单时间开始 */
        orderTimeBegin: string;
        /** 充值账号 */
        rechargeAccount: string;
      }
  > = {}
): Promise<IRequestResult<IBaseShoppingCartItem[]>> {
  return request.post('/user/shopping_cart/list', data, { hideMsg: true, loading: true });
}

export function saveShoppingCart(data: Pick<IBaseShoppingCartItem, 'skuId' | 'amount'>): Promise<IRequestResult> {
  return request.post('/user/shopping_cart/save', data, { msgText: '成功加入购物车' });
}

export function updateShoppingCarItem(data: Pick<IBaseShoppingCartItem, 'skuId' | 'amount' | 'shoppingCartId'>) {
  return request.post('/user/shopping_cart/update', data, { hideMsg: true, loading: true, loadingText: '' });
}

export function deleteShoppingCar(data: Array<IBaseShoppingCartItem['skuId']>) {
  return request.post('/user/shopping_cart/delete', data, { msgText: '删除成功', loading: true, loadingText: '正在删除' });
}
