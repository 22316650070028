









































import { IBaseProduct } from '@/api/type/base';
import { Vue, Component, InjectReactive, Emit } from 'vue-property-decorator';
import { ISkuSelected } from '@/components/Base/BaseSkuSelect/index.vue';
import { ISaleAttr, ISaleAttrData } from '@/api/product';
import { isArray, isEmpty } from 'lodash';
import { calcDeductCredit } from '@/utils/math';
@Component({
  name: ''
})
export default class extends Vue {
  @InjectReactive()
  proDetail!: IBaseProduct & ISaleAttr;

  @InjectReactive()
  saleAttrData!: ISaleAttrData;

  @InjectReactive()
  skuSelectData!: ISkuSelected;

  get selectedTips() {
    const selectedSkuKey = isEmpty(this.skuSelectData) ? {} : Object.keys(this.skuSelectData.selectedSku);
    return this.proDetail.saleAttrList
      .map(item => item.attrName)
      .filter((item, index) => {
        if (isArray(selectedSkuKey)) {
          return !this.skuSelectData.selectedSku[selectedSkuKey[index]];
        }
        return true;
      })
      .join(' / ');
  }

  get selectedSku() {
    if (!this.skuSelectData.selectedSku) {
      return '';
    }
    return Object.keys(this.skuSelectData.selectedSku)
      .map(item => this.skuSelectData.selectedSku[item])
      .filter(item => item)
      .join(' / ');
  }

  /* 根据元来计算所需积分 */
  get deductCredit() {
    return calcDeductCredit(this.proDetail);
  }

  @Emit('buy')
  onShowSku() {
    //
  }
}
