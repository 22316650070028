












import { getProDetail } from '@/api/product';
import { IBaseProduct, ProductTypeEnum } from '@/api/type/base';
import { Vue, Component, ProvideReactive } from 'vue-property-decorator';
import EntityProduct from './EntityProduct.vue';
import VirtualProduct from './VirtualProduct.vue';
@Component({
  name: 'BaseProductDetail',
  components: { EntityProduct, VirtualProduct }
})
export default class extends Vue {
  componentNames = {
    [ProductTypeEnum.PRODUCT_TYPE_PHYSICAL]: 'EntityProduct',
    [ProductTypeEnum.PRODUCT_TYPE_VIRTUAL]: 'VirtualProduct'
  };

  @ProvideReactive()
  proDetail = {} as IBaseProduct;

  async getProDetail(spuId: IBaseProduct['spuId']) {
    const { payload } = await getProDetail(spuId);
    this.proDetail = payload;
    document.title = `${payload.spuName ? payload.spuName : '商品详情'}`;
  }

  created() {
    this.getProDetail(+this.$route.params.id);
  }
}
