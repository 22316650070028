






























import { ISaleAttr } from '@/api/product';
import { IBaseProduct, IBaseStand, ProductTypeEnum } from '@/api/type/base';
import { jumpConfirmOrder } from '@/utils/jump';
import { calcDeductPriceAndCredit } from '@/utils/math';
import { Toast } from 'vant';
import { Vue, Component, InjectReactive } from 'vue-property-decorator';
import VirtualProductFooter from './VirtualProductFooter.vue';

export type createOrderFromItemType = {
  name: string;
  type: string;
  max: number;
  pattern: string;
};
@Component({
  name: '',
  components: { VirtualProductFooter }
})
export default class extends Vue {
  @InjectReactive()
  proDetail!: IBaseProduct & ISaleAttr;

  skuSelectId: IBaseStand['skuId'] = NaN;
  form = {} as Record<string, any>;
  /* 虚拟商品输入列表 */
  get formList(): createOrderFromItemType[] {
    const inputRules = JSON.parse(this.proDetail.inputRules || '[]');
    if (inputRules) {
      return inputRules.rules;
    }
    return [];
  }

  validate(): Promise<void> {
    return new Promise((resolve, reject) => {
      this.formList.forEach((item, index) => {
        if (!this.form[index]) {
          reject();
          Toast(item.name + '格式错误');
          return;
        }
        if (item.pattern) {
          const reg = new RegExp(item.pattern);
          if (!reg.test(this.form[index])) {
            reject();
            Toast(item.name + '格式错误');
            return;
          }
        }
      });

      if (!this.skuSelectId) {
        Toast('请选择规格');
        return;
      }

      resolve();
    });
  }

  async handleClickBuy() {
    await this.validate();

    const rechargeAccount = this.formList.reduce((arr, item, index) => {
      arr.push({ key: item.name, value: this.form[index] });
      return arr;
    }, [] as { key: string; value: string }[]);

    jumpConfirmOrder(
      {
        skuId: this.skuSelectId,
        amount: 1
      },
      ProductTypeEnum.PRODUCT_TYPE_VIRTUAL,
      rechargeAccount
    );
  }

  calcDeduct(e: number) {
    return calcDeductPriceAndCredit(e, this.proDetail);
  }
}
