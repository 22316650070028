import { IBaseStand, ProductTypeEnum } from './../api/type/base';
import router from '@/router';
import { isArray } from 'lodash';

type ConfirmOrderSkuInfo = { skuId: IBaseStand['skuId']; amount: number };
export type ConfirmOrderAccountInfo = { key: string; value: string };
/* 跳转确认订单 */
export function jumpConfirmOrder(skuInfo: ConfirmOrderSkuInfo[] | ConfirmOrderSkuInfo, productType: ProductTypeEnum, account?: ConfirmOrderAccountInfo[]) {
  if (!isArray(skuInfo)) {
    skuInfo = [skuInfo];
  }
  router.push({
    name: 'ConfirmOrder',
    query: {
      skuInfo: JSON.stringify(skuInfo),
      productType,
      account: JSON.stringify(account)
    }
  });
}

/* 跳转确认卡券兑换订单 */
export function jumpConfirmCoupon(data: {
  skuInfo: ConfirmOrderSkuInfo[];
  productType: ProductTypeEnum;
  couponData: { couponNumber: string; couponPassword: string };
  account?: ConfirmOrderAccountInfo[];
}) {
  router.push({
    name: 'ConfirmCoupon',
    query: {
      skuInfo: JSON.stringify(data.skuInfo),
      productType: data.productType,
      account: JSON.stringify(data.account),
      couponData: JSON.stringify(data.couponData)
    }
  });
}
