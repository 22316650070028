




















import { ISaleAttrData } from '@/api/product';
import { isEmpty } from 'lodash';
import { Toast } from 'vant';
import { Vue, Component, Ref, Prop, PropSync, Emit } from 'vue-property-decorator';
import { formatMoney } from '@/filters/calcMoney';
import { IBaseShoppingCartItem } from '@/api/type/base';
import { SkuData } from '@/types/vant';
import { calcDeductCredit, calcDeductPriceAndCredit } from '@/utils/math';
export type ISkuSelected = { selectedSku: Record<string, any>; selectedSkuComb: SkuData['selectedSkuComb']; skuValue: Record<string, any> };

@Component({
  name: ''
})
export default class extends Vue {
  @PropSync('show')
  innerShow!: boolean;

  /*  初始数据格式 */
  @Prop()
  options!: ISaleAttrData;

  @Prop({ default: '确认' })
  confirmText!: string;

  /* 默认选中 */
  @Prop()
  defaultSkuId!: IBaseShoppingCartItem['skuId'];
  /* 默认选中数量 */
  @Prop()
  defaultAmount!: IBaseShoppingCartItem['amount'];

  @Emit('confirm')
  confirm() {
    this.innerShow = false;
    return this.mySku.getSkuData();
  }
  @Emit('select')
  onSkuSelected(skuSelectData: ISkuSelected) {
    this.skuSelected = skuSelectData;
    return skuSelectData;
  }

  @Ref('mySku')
  mySku!: any;
  /* sku选择器选中的值 */
  skuSelected = {} as ISkuSelected;
  /* sku选择器默认选中项 */
  get defaultSku() {
    if (this.skuData.list) {
      const sku = this.skuData.list.find(item => item.id === this.defaultSkuId);
      return {
        ...sku,
        selectedNum: this.defaultAmount
      };
    }
    return {};
  }

  /* 选中的规格详情 */
  get selectedSkuInfo() {
    if (this.options.skuList) {
      return this.options.skuList.find(item => item.skuId === this.skuSelected?.selectedSkuComb?.id);
    }
    return undefined;
  }

  /* 未选择完成显示商品图片 选中显示已选择图片 */
  get skuImg() {
    return this.selectedSkuInfo?.skuImg ?? this.options.imgUrl;
  }
  /* 未选择完成显示商品价格 选中显示已选择价格 */
  get skuPrice() {
    if (this.selectedSkuInfo) {
      const deduct = calcDeductPriceAndCredit(this.selectedSkuInfo.currentPrice, this.options);
      return formatMoney(deduct.price);
    } else {
      return formatMoney(this.options.minPrice);
    }
  }
  /* 未选择完成显示总剩余库存 选中显示已选择规格剩余库存 */
  get skuStockNum() {
    return this.skuSelected?.selectedSkuComb?.stock_num ?? this.skuData.stock_num;
  }

  get skuData() {
    if (isEmpty(this.options)) return {};
    return {
      tree: this.conversionTree(this.options.saleAttrList),
      list: this.conversionSkuList(this.options.skuList),
      stock_num: this.options.usableInventory
    };
  }

  /* 根据元来计算所需积分 */
  get deductPrice() {
    if (this.selectedSkuInfo) {
      const deduct = calcDeductPriceAndCredit(this.selectedSkuInfo.currentPrice, this.options);
      return deduct.credit;
    } else {
      return calcDeductCredit(this.options);
    }
  }

  validate() {
    if (this.mySku.validateSku()) {
      Toast(this.mySku.selectedText);
      return;
    }
    this.confirm();
  }

  /* 转换选择项 */
  conversionTree(saleAttrList: ISaleAttrData['saleAttrList']) {
    const tree = saleAttrList.map(item => {
      return {
        k: item.attrName,
        k_s: 's' + item.attrId,
        v: item.attrValueList.map(item => {
          return {
            id: item,
            name: item
          };
        })
      };
    });
    return tree;
  }

  /* 转换所有规格信息 */
  conversionSkuList(skuList: ISaleAttrData['skuList']) {
    return skuList.map(item => {
      const conversionItem = {
        id: item.skuId,
        price: item.currentPrice,
        stock_num: item.usableInventory
      };

      item.saleAttrList.forEach(attr => {
        Object.assign(conversionItem, {
          [`s${attr.attrId}`]: attr.attrValue
        });
      });

      return conversionItem;
    });
  }
}
