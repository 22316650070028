







import { IBaseProduct } from '@/api/type/base';
import { Vue, Component, InjectReactive } from 'vue-property-decorator';
@Component({
  name: ''
})
export default class extends Vue {
  @InjectReactive()
  proDetail!: IBaseProduct;
}
