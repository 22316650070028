










import { IBaseProduct, ProductTypeEnum } from '@/api/type/base';
import { ImagePreview } from 'vant';
import { Vue, Component, InjectReactive } from 'vue-property-decorator';
@Component({
  name: ''
})
export default class extends Vue {
  current = 0;

  @InjectReactive()
  proDetail!: IBaseProduct;

  get imgList() {
    return this.proDetail.imgList;
  }

  get productTypeEnum() {
    return ProductTypeEnum;
  }

  onChange(index: number) {
    this.current = index;
  }
  /* 点击预览 */
  handleClickSwiperItem() {
    ImagePreview({
      startPosition: this.current,
      images: this.imgList
    });
  }

  mounted() {
    // ProductTypeEnum.PRODUCT_TYPE_PHYSICAL
    // console.log();
  }
}
