







import VirtualProductHeader from './comm/VirtualProductHeader.vue';
import ProductContent from './comm/ProductContent.vue';
import { Vue, Component } from 'vue-property-decorator';
import VirtualProductMain from './comm/VirtualProductMain.vue';
@Component({
  name: '',
  components: { VirtualProductHeader, ProductContent, VirtualProductMain }
})
export default class extends Vue {}
