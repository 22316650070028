






















import { Vue, Component, Ref, InjectReactive, ProvideReactive } from 'vue-property-decorator';
import EntityProductHeader from './comm/EntityProductHeader.vue';
import ProductContent from './comm/ProductContent.vue';
import EntityProductMain from './comm/EntityProductMain.vue';
import EntityProductFooter from './comm/EntityProductFooter.vue';
import BaseSkuSelect from '@/components/Base/BaseSkuSelect/index.vue';
import { IBaseProduct } from '@/api/type/base';
import { getSaleAttr } from '@/api/product';
import { SkuData } from '@/types/vant';
import { saveShoppingCart } from '@/api/shoppingCar';
import { jumpConfirmOrder } from '@/utils/jump';
import { ISkuSelected } from '@/components/Base/BaseSkuSelect/index.vue';

@Component({
  name: '',
  components: { EntityProductHeader, EntityProductMain, ProductContent, EntityProductFooter, BaseSkuSelect }
})
export default class extends Vue {
  skuSelectShow = false;
  skuSelectBtnText = '确定';

  @Ref('myMain')
  myMain!: EntityProductMain;

  @InjectReactive()
  proDetail!: IBaseProduct;
  /* sku信息 */
  @ProvideReactive()
  saleAttrData = {};

  /* sku信息 */
  @ProvideReactive()
  skuSelectData = {} as ISkuSelected;

  onShowSkuBuy() {
    this.skuSelectBtnText = '立即购买';
    this.skuSelectShow = true;
  }

  onShowSkuAddCart() {
    this.skuSelectBtnText = '加入购物车';
    this.skuSelectShow = true;
  }

  onSkuSelectChange(skuSelectData: ISkuSelected) {
    this.skuSelectData = skuSelectData;
  }

  onConfirm(selected: SkuData) {
    if (this.skuSelectBtnText === '加入购物车') {
      saveShoppingCart({
        skuId: selected.selectedSkuComb.id,
        amount: selected.selectedNum
      });
    } else {
      jumpConfirmOrder([{ skuId: selected.selectedSkuComb.id, amount: selected.selectedNum }], this.proDetail.type);
    }
  }

  async getSaleAttr() {
    const { payload } = await getSaleAttr(this.proDetail.spuId);
    this.saleAttrData = payload;
  }

  created() {
    this.getSaleAttr();
  }
}
