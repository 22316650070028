









import { cancelFavorites, ISaleAttr, saveFavorites, selectIsFavorites } from '@/api/product';
import { IBaseProduct } from '@/api/type/base';
import { UserModule } from '@/store/modules/User';
import { Vue, Component, Emit, InjectReactive } from 'vue-property-decorator';
@Component({
  name: ''
})
export default class extends Vue {
  isCollect = false;

  @InjectReactive()
  proDetail!: IBaseProduct & ISaleAttr;

  // 查询是否收藏
  async getIsFavorites() {
    if (!UserModule.userInfo.userId) return;
    const { payload } = await selectIsFavorites(this.proDetail.spuId);
    this.isCollect = payload > 0;
  }

  // 点击收藏
  async handleCollect() {
    if (this.isCollect) {
      // 收藏
      await cancelFavorites([this.proDetail.spuId]);
      this.isCollect = false;
    } else {
      // 取消收藏
      await saveFavorites([this.proDetail.spuId]);
      this.isCollect = true;
    }
  }

  @Emit('handle-click-buy')
  buy() {
    //
  }

  created() {
    this.getIsFavorites();
  }
}
